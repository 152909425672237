/* Google Fonts + CSS retrieved via https://google-webfonts-helper.herokuapp.com/fonts

/* merriweather-regular - latin-ext_latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/merriweather-v22-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/merriweather-v22-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* merriweather-italic - latin-ext_latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('/fonts/merriweather-v22-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/merriweather-v22-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* merriweather-700 - latin-ext_latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/merriweather-v22-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/merriweather-v22-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* merriweather-700italic - latin-ext_latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('/fonts/merriweather-v22-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/merriweather-v22-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-regular - latin-ext_latin */
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-500 - latin-ext_latin */
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/fira-sans-extra-condensed-v5-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-extra-condensed-v5-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-600 - latin-ext_latin */
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-extra-condensed-700 - latin-ext_latin */
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/fira-sans-extra-condensed-v5-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-code-regular - latin-ext_latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/fonts/fira-code-v10-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/fira-code-v10-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-code-700 - latin-ext_latin */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/fonts/fira-code-v10-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/fira-code-v10-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
