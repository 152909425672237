@import "partials/_fonts";
@import "partials/_syntax_highlight";

$font-body: Merriweather, serif;
$font-heading: "Fira Sans Extra Condensed", sans-serif;
$font-code: "Fira Code", monospace;
$color-background: rgb(250, 250, 250);
$color-text: rgb(34, 34, 34);
$color-accent: rgb(12, 91, 163); // #0C5BA3
$color-accent-background: rgb(240, 240, 240);

:root {
  --color-background: #{$color-background};
  --color-text: #{$color-text};
  --color-text-subdued: #{rgba($color-text, 0.75)};
  --color-accent: #{$color-accent};
  --color-accent-subdued: #{rgba($color-accent, 0.85)};
  --color-accent-darker: #{darken($color-accent, 12)};
  --color-note: #{$color-accent};
  --color-note-update: rgb(96, 96, 96);
  --color-note-text-header: rgb(255, 255, 255);
  --color-note-background: #{$color-accent-background};
  --color-toc: #{$color-accent};
  --color-toc-background: #{$color-accent-background};
  --color-border: #{rgba($color-text, 0.25)};
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #{$color-text};
    --color-text: #{$color-background};
    --color-text-subdued: #{rgba($color-background, 0.75)};
    --color-accent: #{lighten($color-accent, 25)};
    --color-accent-subdued: #{lighten($color-accent, 40)};
    --color-accent-darker: #{lighten($color-accent, 10)};
    --color-note: #{$color-accent};
    --color-note-update: rgb(96, 96, 96);
    --color-note-text-header: rgb(255, 255, 255);
    --color-note-background: #{lighten($color-text, 10)};
    --color-toc: #{lighten($color-accent, 25)};
    --color-toc-background: #{lighten($color-text, 4)};
    --color-border: #{rgba($color-background, 0.25)};
  }
}

* {
  box-sizing: border-box;
  margin-bottom: 0;
}

body {
  color: var(--color-text);
  font-family: $font-body;
  font-size: 18px;
  line-height: 1.5;
  margin: 0.5rem 2.5% 0 2.5%;
  background-color: var(--color-background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 1em;

  a {
    text-decoration: none;
  }
}

h1 {
  font-size: 2.1em;
}

h2 {
  font-size: 1.8em;
  font-weight: 400;

  + p {
    margin-top: 0.7em;
  }

  &.list_entry {
    font-weight: 600;
  }
}

h3 {
  font-size: 1.5em;

  + p {
    margin-top: 0.3em;
  }
}

h4 {
  font-size: 1.2em;

  + p {
    margin-top: 0.25em;
  }
}

h5 {
  font-size: 1em;

  + p {
    margin-top: 0.25em;
  }
}

code,
pre,
kbd {
  font-family: $font-code;
  // Fira Code has ligatures which I like when coding, but may be unexpected
  // and thus confusing for someone looking at my code examples.
  font-variant-ligatures: none;
}

pre {
  overflow: auto;
}

blockquote {
  position: relative;
  font-style: italic;
  border: 0;
  padding: 0 1em 0 1em;
  margin: 0;

  &::before {
    font-size: 2em;
    color: var(--color-accent);
    content: "“";
    left: -0.1em;
    top: -0.15em;
    position: absolute;
  }
}

figure {
  margin: 1em 0 1em 0;

  figcaption {
    font-style: italic;
  }
}

dt {
  font-weight: 700;
}

// Tables

caption {
  caption-side: bottom;
  font-style: italic;
}

table {
  text-align: left;
  border-spacing: 0;
  margin-top: 1em;

  thead {
    background-color: var(--color-background);
  }

  td,
  th {
    padding: 3px 3px 3px 0.5em;
    border: 0px;
    border-bottom: 1px solid var(--color-border);
  }

  th {
    font-weight: bold;
    border-bottom: 1px solid var(--color-text);
  }
}

a {
  color: var(--color-accent);
  text-decoration: underline var(--color-accent);

  &:visited {
    color: var(--color-accent-darker);
    text-decoration: underline var(--color-accent-darker);
  }

  &:hover {
    text-decoration: none;
  }

  &.tag {
    padding: 0.2em;
    border-radius: 0.5em;
    border: 1px solid var(--color-accent-subdued);
    text-decoration: none;
  }
}

li ul,
li ol,
dd ul,
dd ol {
  margin-top: 0;
}

ul {
  margin-top: 0.5em;
}

li p {
  margin-top: 0;
}

header + details {
  margin-top: 1.5em;
}

img {
  max-width: 100%;
  padding: 3px;
  border: 1px solid var(--color-border);
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: $font-heading;

  a,
  a:visited {
    color: var(--color-accent);
    text-decoration: none;
  }

  #title {
    font-weight: 600;
    font-size: 1.5em;
  }

  #menu {
    align-items: end;
    display: flex;
    gap: 1em;
    list-style: none;
    padding: 0;
    margin: .5em 0 0 0;
  }
}

figcaption {
  font-style: italic;
  font-size: 0.9em;

  p {
    margin-top: 0;
  }
}

strong,
b {
  font-weight: 700;
}

pre {
  padding: 3px;
  border: 1px solid var(--color-border);
}

q {
  font-style: italic;
}

#search {
  margin-top: 1em;
}

#article_list_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .feed_link {
    align-self: flex-end;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: auto minmax(25ch, 60ch) auto;
}

.wrapper > header {
  grid-column: 2/3;
  grid-row: 1/2;
  border-bottom: 1px var(--color-accent) solid;
}

.wrapper > main {
  grid-column: 2/3;
  grid-row: 2/3;
}

.wrapper > footer {
  grid-column: 2/3;
  grid-row: 3/4;
  margin-top: 3rem;
  margin-bottom: 1em;

  p {
    font-family: $font-heading;
    font-size: 0.9rem;
    line-height: 1;
    margin-top: 0.5rem;
    color: var(--color-text-subdued);
  }

  a,
  a:visited {
    color: var(--color-accent-subdued);
    text-decoration: none;
  }
}

.numeric {
  text-align: right;
}

.table-wrapper {
  padding-bottom: 1em;
}

.metadata {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.9em;
  font-family: $font-heading;
  line-height: 1.75em;

  a,
  a:visited {
    color: var(--color-accent-subdued);
  }

  .updated {
    padding-left: 0.5em;
    color: var(--color-text-subdued);
  }

  > div {
    margin-top: 0.5em;
  }
}

.posts header + p {
  margin-top: 3em;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.page .metadata .updated,
.tags .metadata .updated {
  display: none;
}

.note {
  border: 1px var(--color-note) solid;
  margin-top: 1em;

  &.update {
    --color-note: var(--color-note-update);
  }

  .note_header {
    background-color: var(--color-note);
    color: var(--color-note-text-header);
    padding: 0 0.5em 0 0.5em;
    font-family: $font-heading;
    font-size: 1.1em;
    font-weight: 700;
    min-height: 0.5em;
  }

  .note_body {
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: var(--color-note-background);

    p:first-child {
      margin-top: 0;
    }
  }
}

.hidden {
  display: none;
}

#toc {
  summary {
    color: var(--color-toc);
    font-family: $font-heading;
    font-size: 1.1em;
    font-weight: 700;
    min-height: 0.5em;
  }

  &[open] {
    background-color: var(--color-toc-background);
  }
}

#TableOfContents {
  display: block;
  padding: 0 0.5em 0 0.5em;

  ul {
    display: block;
    margin-left: 1em;
    list-style: disc;
    color: var(--color-toc);
  }
}

.metadata-list {
  font-size: 0.9em;
  font-family: $font-heading;
  display: block;
}

.archive-list {
  list-style: none;
  padding-bottom: 0.5em;
  font-family: $font-heading;

  a {
    font-weight: 500;
    font-size: 1.2em;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.paginator {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5em;
  font-family: $font-heading;

  p {
    margin-top: 0;
  }
}

.float-left {
  float: left;
  margin: 0 1em 0 0;
}

.float-right {
  float: right;
  margin: 0 0 0 1em;
}
// Fix unreadable highlight
.chroma .hl {
  background-color: #7b7201;
}
